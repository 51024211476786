
import { defineComponent, onMounted, ref } from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Mutual } from "@/core/model/mutual";
import { Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import AddMutualModal from "@/views/mutuales/AddMutualModal.vue";
import MutualInfo from "@/views/mutuales/MutualInfo.vue";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

export default defineComponent({
  name: "admin-mutual-profile",
  components: {
    MutualInfo,
    AddMutualModal,
  },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const store = useStore();
    const mutual = ref<Mutual>({} as Mutual);
    const actionModal = "edit";

    onMounted(() => {
      setCurrentPageActions(false, false, []);
      setCurrentPageBreadcrumbs("Administrar Mutual", [
        "Mutuales",
        "Información",
      ]);
      loadData();
    });

    const loadData = () => {
      loading.value = true;
      store.dispatch(Actions.ADMIN_MUTUAL_GET, route.params.uuid).then(() => {
        mutual.value = store.state.AdminMutualesModule.mutual;
        loading.value = false;
      });
    };

    const formatDateTime = (value) => {
      return value === undefined
        ? ""
        : moment(value).format("DD/MM/YYYY hh:mm a");
    };

    return {
      loading,
      mutual,
      formatDateTime,
      actionModal,
      loadData,
    };
  },
});
